<template>
  <div class="header">
    <div class="logo">
      <img src="../assets/logo.png" alt srcset />
    </div>
    <div class="menu">
      <div class="nav">
        <van-tabs
          v-model="number"
          line-width="2rem"
          line-height=".5rem"
          title-active-color="#b70903"
          class="navTab"
          @click="changeRoute"
        >
          <van-tab
            v-for="(item,index) in navList"
            :title="item.title"
            :key="index"
            title-style="font-size:2.6rem; padding:0 1.5rem;"
          />
        </van-tabs>
      </div>
      <div class="spread">
        <a href="javascript:void(0)" @click="showPopup">
          <img src="../assets/icon-spread.png" alt />
        </a>
      </div>
    </div>
    <van-popup v-model="show" position="top" :style="{ height: '30rem'}">
      <div style="padding:2rem 0">
        <van-grid :border="false" :column-num="4" :gutter="8">
          <van-grid-item
            v-for="(item,index) in navList"
            :key="'n'+index"
            class="popnav-item"
            @click="jumpPage(item)"
          >{{ item.title }}</van-grid-item>
        </van-grid>
      </div>
    </van-popup>
  </div>
</template>
<script>
export default {
  name: "HeadNav",
  props: {
    active: {
      type: Number,
      default: 0
    }
  },
  computed: {
    number: {
      get() {
        return parseInt(this.$props["active"]);
      },
      set(val) {
        return val;
      }
    }
  },
  data() {
    return {
      show: false,
      navList: [],
      actived: 3
    };
  },
  created() {
    this.loadMenu();
  },
  methods: {
    loadMenu: function() {
      var that = this;
      that.$api.getMenudata().then(res => {
        if (res.status == "success") {
          that.navList = res.data;
        }
      });
    },
    changeRoute(name) {
      var item = this.navList[name];
      this.jumpPage(item);
    },
    jumpPage(item) {
      this.$emit('change-title', item.title);
      this.$router.push({
        path: item.href,
        query: item.query
      });
    },
    showPopup: function() {
      this.show = true;
    }
  }
};
</script>
<style lang="less" scoped="">
.header {
  box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.2);
}
.logo {
  height: 5rem;
  background: #b70903;
  padding: 1rem;
  text-align: left;
}
.logo img {
  height: 5rem;
  vertical-align: top;
}
.menu {
  position: relative;
  height: 6rem;
}
.navTab /deep/ .van-tabs--line {
  height: 6rem;
}

.navTab /deep/ .van-tabs__wrap {
  height: 6rem;
}
.spread {
  width: 4rem;
  height: 4rem;
  position: absolute;
  right: 0rem;
  top: 0.5rem;
  background: #fff;
  padding: 0.5rem;
}
.spread a {
  width: 4rem;
  height: 4rem;
  display: block;
}
.spread img {
  width: 4rem;
  height: 4rem;
}

/* 修改当前激活Tab的样式 */
van-tab.van-tab--active {
  color: #b70903;
  border-bottom: 2px solid #b70903;
  font-weight: bold;
}

/deep/.van-grid-item .van-grid-item__content {
  padding: 1rem .2rem;
  background: #e3e3e3;
  border-radius: 0.5rem;
  color: #666;
  font-size: 2.6rem;
}

</style>
